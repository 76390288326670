import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path:'/',
    name:'index',
    
    redirect:'/emqx'
  },
  {
    path:'/emqx',
    name:'emqx',
    meta:{keepAlive:false},
    component:()=>import('../views/EmqxView.vue')
  },
  {
    path:'/login',
    name:'login',
    meta:{keepAlive:true},
    component:()=>import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta:{keepAlive:false},
    component: ()=>import('../views/HomeView.vue'),
    redirect:'/main',
    children:[
      {path:'/main',name:'首页',meta:{keepAlive:false},component:()=>import('../views/MainView.vue')},
      {path:'/room',name:'配电房管理',meta:{keepAlive:false},component: ()=>import('../views/ElcRoomView.vue')},
      {path:'/enterprise',name:'企业管理',meta:{keepAlive:false},component:()=>import('../views/ElcEnterpriseView.vue')},
      {path:'/station',name:'站点管理',meta:{keepAlive:false},component:()=>import('../views/ElcStationView.vue')},
      {path:'/user',name:'用户管理',meta:{keepAlive:false},component:()=>import('../views/ElcUserView.vue')},
      {path:'/gateoption',name:'网关管理',meta:{keepAlive:false},component:()=>import('../views/ElcGateOptionView.vue')},
      {path:'/metoption',name:'馈线管理',meta:{keepAlive:false},component:()=>import('../views/ElcMetOptionView.vue')},
      {path:'/warn',name:'告警事件',meta:{keepAlive:false},component:()=>import('../views/ElcWarnView.vue')},
      {path:'/admin',name:'管理员管理',meta:{keepAlive:false},component:()=>import('../views/ElcAdminView.vue')},
      {path:'/inlet',name:'进线管理',meta:{keepAlive:false},component:()=>import('../views/ElcInletView.vue')},
      {path:'/cab',name:'配电柜管理',meta:{keepAlive:false},component:()=>import('../views/ElcCabView.vue')},
      {path:'/doc',name:'文章管理',meta:{keepAlive:false},component:()=>import('../views/ElcArticleView.vue')},
      {path:'/product',name:'产品管理',meta:{keepAlive:false},component:()=>import('../views/ElcProductView.vue')},
      {path:'/adv',name:'广告管理',meta:{keepAlive:false},component:()=>import('../views/ElcAdvView.vue')},
      {path:'/act',name:'活动管理',meta:{keepAlive:false},component:()=>import('../views/ElcActView.vue')},
      {path:'/data',name:'数据管理',meta:{keepAlive:false},component:()=>import('../views/ElcDataView.vue')},
      {path:'/3dmap',name:'站点3d情况',meta:{keepAlive:false},component:()=>import('../views/Elc3DView.vue')},
      {path:'/build',name:'快速建站功能',meta:{keepAlive:true},component:()=>import('../views/ElcBuildView2.vue')},
      {path:'/mqtt',name:'Mqtt管理',meta:{keepAlive:false},component:()=>import('../views/ElcMqttView.vue')}
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
